import React, { useMemo, useState, useEffect } from "react";
import Landing from "../components/Landing"

const IndexPage = () => (
    <div>
        <Landing />
    </div>
)

export default IndexPage
